.classRowFieldTable {
    display: "inline-block";
    margin-bottom: 0px;
    > .ant-col-16 {
      max-width: 100%;
    }
    > .ant-form-item-control{
      > .ant-form-item-explain{
        min-height: 10px;
      }
    }
  }
  .ant-form-item-with-help .ant-form-item-explain{
    min-height: 4px;
}
.ant-advanced-search-form {
    padding: 10px;
    background: #fbfbfb;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    margin-bottom: 5px;
}
.ant-advanced-search-form> .ant-row .ant-form-item {
  margin: 0 0 15px;
  }
.ant-card-body{
  padding: 16px;
}
.FormModal .ant-row .ant-form-item {
  margin: 0 0 15px;
  }
.ant-descriptions-header{
    margin-bottom: 10px;
  }

.ui-form .ant-form-item{
    margin-bottom: 8px;
  }
.ui-descriptions{
  margin-top: 10px;
  >.ant-descriptions-header{
    margin-bottom: 10px;
  }
}
.ui-table .ant-table.ant-table-bordered > .ant-table-title{
    border: 0px;
    // background-color: aqua;
  }
  
.ul-errorlist{
  margin-bottom: 0px;
}

.ui_button_upload{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #08080e;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  outline: 0;
  &_select{
      display: inline-block;
      &_picture_card{
          width: 230px;
          height: 250px;
          margin-right: 8px;
          margin-bottom: 8px;
          text-align: center;
          vertical-align: middle;
          background-color: #fafafa;
          border: 1px dashed #d9d9d9;
          border-radius: 2px;
          cursor: pointer;
          transition: border-color .3s;
          &_display_text{
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
              text-align: center;
          }
          &_image{
              position: static;
              display: block;
              align-items: center;
              justify-content: center;
              width: '100%';
              height:'100%';
              text-align: center;
              padding: 5px;
          }
      }
  }
}

.ant-divider-ui{
  margin: 8px 0;
}